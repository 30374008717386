import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { Error as ErrorIcon } from "@mui/icons-material";
import { observer } from "mobx-react";
import { useStores } from "../../../stores";
import StyleVariables from "../../../assets/styles/Variable.module.scss";
import {
  RenewBillTypes as RenewBillTypesConstant,
  RenewBillTypeKey as RenewBillTypeKeyConstant,
} from "../../../constants/Deposit";
import { KeysType as KeysTypePaymentConstant } from "../../../constants/Payment";
import { ProductWeightType as ProductWeightTypeConstant } from "../../../constants/Gold";
import RadioSelectionInput from "../../inputs/RadioSelection";
import ToInterestDepositRenewBillForm from "./ToInterest";
import ReduceDepositRenewBillForm from "./Reduce";
import IncreaseRenewBillForm from "./Increase";
import NumberInput from "../../inputs/Number";
import { NumericFormat } from "react-number-format";
import {
  CalculateMaxIncreaseDepositPrice as CalculateMaxIncreaseDepositPriceUtil,
  CalculatePurchaseNegotiation as CalculatePurchaseNegotiationUtil,
} from "../../../utils/Gold";
import PurchaseRenewBillForm from "./Purchase";

type Props = {};

const RenewBill = observer((props: Props) => {
  const { DepositStore, PaymentStore, MainStore, TradeStore } = useStores();

  const onTypeChange = (type: string) => {
    DepositStore.onClearRenewBillForm();
    const form: any = JSON.parse(JSON.stringify({ ...DepositStore.form }));
    let formRenew: any = JSON.parse(
      JSON.stringify({ ...DepositStore.formRenew })
    );
    const formRenewIsInvalid: any = JSON.parse(
      JSON.stringify({ ...DepositStore.formRenewIsInvalid })
    );
    const formIsInvalid: any = JSON.parse(
      JSON.stringify({ ...DepositStore.formIsInvalid })
    );
    formRenew.goldSellPrice = MainStore.goldSellNow;
    formRenew.goldPurchasePrice = MainStore.goldPurchaseNow;
    formRenew.goldPriceDateTime = MainStore.goldDateTime;
    formRenew.purchaseType = "";
    formRenew.purchasePriceCurrentStatus = "good";
    formRenew.purchaseStandardExcellent = 0;
    formRenew.purchaseStandardGood = 0;
    formRenew.purchaseStandardFair = 0;
    formRenew.purchaseStandardPoor = 0;
    form.type = type;
    form.period = form.defaultPeriod;
    form.cardPay = 0;
    form.paymentType = KeysTypePaymentConstant.allCash;
    form.percentChargeCard = 0;
    form.chargeCard = 0;
    formIsInvalid.type = false;
    formIsInvalid.cardPay = false;
    switch (type) {
      case RenewBillTypeKeyConstant.return:
        form.totalPay = form.depositPrice + form.interest;
        form.netPay = form.depositPrice + form.interest;
        formRenew.newDepositPrice = 0;
        formRenew.reducePrice = 0;
        formRenew.increasePrice = 0;
        formRenew.increasePriceStandard = 0;
        formRenew.purchasePriceStandard = 0;
        break;
      case RenewBillTypeKeyConstant.toInterest:
        form.totalPay = form.interest;
        form.netPay = form.interest;
        formRenew.newDepositPrice = DepositStore.form.depositPrice;
        formRenew.reducePrice = 0;
        formRenew.increasePrice = 0;
        formRenew.increasePriceStandard = 0;
        formRenew.purchasePriceStandard = 0;
        break;
      case RenewBillTypeKeyConstant.reduce:
        form.totalPay = 0;
        form.netPay = 0;
        formRenew.newDepositPrice = 0;
        formRenew.reducePrice = 0;
        formRenew.increasePrice = 0;
        formRenew.increasePriceStandard = 0;
        formRenew.purchasePriceStandard = 0;
        break;
      case RenewBillTypeKeyConstant.increase:
        form.totalPay = 0;
        form.netPay = 0;
        formRenew.newDepositPrice = 0;
        formRenew.reducePrice = 0;
        formRenew.increasePrice = 0;
        formRenew.increasePriceStandard = CalculateMaxIncreaseDepositPriceUtil(
          formRenew.goldPurchasePrice,
          form.productPercentage,
          form.totalWeight,
          form.period,
          form.depositPriceStandard,
          form.depositPrice
        );
        formRenew.purchasePriceStandard = 0;
        break;
      case RenewBillTypeKeyConstant.purchase:
        const {
          purchasePriceStandard,
          purchasePriceStandardExcellent,
          purchasePriceStandardGood,
          purchasePriceStandardFair,
          purchasePriceStandardPoor,
        } = TradeStore.calculatePurchaseRangeForValidate(
          form.productPercentage,
          formRenew.goldPurchasePrice,
          form.totalWeight
        );
        const priceNegotiation = CalculatePurchaseNegotiationUtil(
          formRenew.goldPurchasePrice,
          form.productPercentage,
          form.totalWeight
        );
        form.totalPay = purchasePriceStandard - priceNegotiation;
        form.netPay =
          purchasePriceStandard > 0
            ? purchasePriceStandard -
              (form.depositPrice + form.interest) -
              priceNegotiation
            : 0;
        formRenew.newDepositPrice = 0;
        formRenew.reducePrice = 0;
        formRenew.increasePrice = 0;
        formRenew.increasePriceStandard = 0;
        formRenew.purchaseType = !form.productPercentage
          ? ProductWeightTypeConstant.manual
          : ProductWeightTypeConstant.equalWeight;
        formRenew.purchasePriceStandard = purchasePriceStandard;
        formRenew.purchasePriceNegotiation = priceNegotiation;
        formRenew.purchasePriceCurrentStatus = "good";
        formRenew.purchaseStandardExcellent = purchasePriceStandardExcellent;
        formRenew.purchaseStandardGood = purchasePriceStandardGood;
        formRenew.purchaseStandardFair = purchasePriceStandardFair;
        formRenew.purchaseStandardPoor = purchasePriceStandardPoor;
        break;
      default:
        break;
    }
    Object.keys(formRenewIsInvalid).forEach((key) => {
      formRenewIsInvalid[key] = false;
    });
    PaymentStore.onClearStore(form.netPay);
    DepositStore.onSetForm({ ...form });
    DepositStore.onSetFormRenew({ ...formRenew });
    DepositStore.onSetFormIsInvalid({ ...formIsInvalid });
    DepositStore.onSetFormRenewIsInvalid({ ...formRenewIsInvalid });
  };

  const renderRenewBillForm = () => {
    switch (DepositStore.form.type) {
      case RenewBillTypeKeyConstant.return:
        return (
          <Grid
            container
            spacing={{ xs: 3, md: 2 }}
            rowSpacing={2}
            alignItems={"flex-end"}
          >
            {/* TotalPay */}
            <Grid item xs={12} md={6} marginTop={{ xs: 0, md: 1 }}>
              <NumberInput
                name="totalPay"
                label="รับเงินจากลูกค้า"
                placeholder="กรอกอัตโนมัติ"
                value={`${DepositStore.form.totalPay || ""}`}
                isDisabled={true}
                inputPropsClassName="input-highlight"
                handleOnChange={(value: number) => {}}
              />
            </Grid>
            {/* /.TotalPay */}
          </Grid>
        );
      case RenewBillTypeKeyConstant.toInterest:
        return <ToInterestDepositRenewBillForm />;
      case RenewBillTypeKeyConstant.reduce:
        return <ReduceDepositRenewBillForm />;
      case RenewBillTypeKeyConstant.increase:
        return <IncreaseRenewBillForm />;
      case RenewBillTypeKeyConstant.purchase:
        return <PurchaseRenewBillForm />;
      default:
        return <></>;
    }
  };

  return (
    <Box marginTop={3}>
      <Typography
        variant="h6"
        fontWeight={"bold"}
        color={StyleVariables["color-header"]}
      >
        เลือกประเภทการต่อบิล
      </Typography>
      <Box display={"flex"} flexWrap={"wrap"} marginTop={3}>
        {RenewBillTypesConstant.map((type, index) => {
          return (
            <Box
              key={index}
              marginRight={3}
              display={"flex"}
              alignItems={"center"}
            >
              <RadioSelectionInput
                name="renewBillType"
                label={type.label}
                selectedValue={DepositStore.form.type}
                value={type.value}
                onChange={(value: any) => {
                  onTypeChange(value);
                }}
              />
              {type.value === RenewBillTypeKeyConstant.purchase ? (
                <Typography
                  variant="body2"
                  fontWeight={"bold"}
                  color={StyleVariables["color-primary"]}
                  marginLeft={-1}
                >
                  {`(ยอดค้าง `}
                  <NumericFormat
                    value={
                      DepositStore.form.depositPrice +
                      Number(DepositStore.form.interest || 0)
                    }
                    displayType="text"
                    thousandSeparator
                  />
                  {`)`}
                </Typography>
              ) : null}
            </Box>
          );
        })}
      </Box>
      {DepositStore.formIsInvalid.type ? (
        <Box display={"flex"} alignItems={"center"} gap={1}>
          <ErrorIcon
            sx={{ fontSize: 32, color: StyleVariables["color-error-icon"] }}
          />
          <Typography
            variant="subtitle1"
            fontWeight={"medium"}
            color={StyleVariables["color-error"]}
          >
            กรุณาเลือกประเภทการต่อบิล
          </Typography>
        </Box>
      ) : null}
      <Box marginTop={3}>{renderRenewBillForm()}</Box>
    </Box>
  );
});

export default RenewBill;
